import { router } from '@inertiajs/react'
import { useContext } from 'react'
import { EffectorContext } from '$root/utils/effector/effector-services'

const InvoiceListHeaderActions = ({ routes }) => {
  const services = useContext(EffectorContext)

  const { invoicesService } = services

  const newInvoice = invoicesService.newInvoice.bind(invoicesService)

  const showInvoicesModal = () => {
    router.get(route(routes.invoices.list, route().params))
  }

  return (
    <div className="invoice-list__header-actions">
      <div onClick={newInvoice}>Новый счет</div>
      <div onClick={showInvoicesModal}>Все счета</div>
    </div>
  )
}

export default InvoiceListHeaderActions

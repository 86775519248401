import React from 'react'
import { useStore } from 'effector-react'

import { $menuIsShort, menuStateIsChanged } from '$models/menu'

export const Logo = ({ onClick, href }) => {
  const menuIsShort = useStore($menuIsShort)

  const logoClickHandler = (event) => {
    if (onClick) {
      onClick()
    } else {
      event.preventDefault()
      menuStateIsChanged(!menuIsShort)
    }
  }

  const Wrapper =
    (href &&
      (({ children }) => (
        <a href={href} style={{ width: '100%' }}>
          {children}
        </a>
      ))) ||
    (({ children }) => children)

  return (
    <Wrapper>
      <div className="sidebar__logo" onClick={logoClickHandler} />
    </Wrapper>
  )
}

import { Component } from 'react'
import * as Sentry from '@sentry/react'
import { DisplayError } from '$root/pages/Error'

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: null }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error)
    console.error('Caught an error:', error, errorInfo)
  }

  render() {
    console.log('RENDER!', this.state)
    if (this.state.hasError) {
      return (
        <DisplayError
          status={500}
          title="Непредвиденная ошибка"
          // description={this.state.error.toString()}
        />
      )
    }

    return this.props.children
  }
}

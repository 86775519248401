import * as Sentry from '@sentry/react'

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN
const APP_ENV = import.meta.env.VITE_APP_ENV

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: APP_ENV,
    integrations: [
      Sentry.browserTracingIntegration(),
      // Sentry.replayIntegration({
      //   maskAllText: false,
      //   blockAllMedia: false,
      // }),
    ],
    enabled: true,
    /*
        beforeSend(event, hint) {
            if (event.exception) {
                Sentry.showReportDialog({
                    title: 'Произошла непредвиденная ошибка',
                    subtitle: 'Расскажите нам какие действия вы выполняли, что привело к этой ошибке: ',
                    subtitle2: event.exception.values && event.exception.values.length ? event.exception.values[0].value: null,
                    labelName: 'Ваше имя',
                    labelEmail: 'Ваш email',
                    labelComments: 'Что случилось? Что вы пытались сделать?',
                    labelClose: 'Закрыть',
                    labelSubmit: 'Отправить',
                    errorGeneric: 'Что-то пошло не так, попробуйте снова',
                    errorFormEntry: 'Ошибка проверки полей формы',
                    successMessage: 'Ваша пробелма успешно отправлена. Спасибо за обратную связь!',
                    eventId: event.event_id,
                })
            }
            return event
        },
        */
    tracesSampleRate: 0.1,
    // Session Replay
    // replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

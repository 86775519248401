import { usePage } from '@inertiajs/react'
import { DeliveryInfoField } from '$components/Clients/Orders/OrdersByStatus/Fields/DeliveryInfoField'
import { useContext, useMemo } from 'react'
import {
  EffectorContext,
  EffectorServices,
} from '$root/utils/effector/effector-services'
import { useModals } from '$hooks/useModals'
import { Modal } from '$components/UI/Modals/Modal'
import EditOneInvoice from '$components/Clients/Invoices/EditOneInvoice'
import { ContactType } from '$root/utils/constants/clients'
import { DeliveryActionsStore } from '$lib/deliveries/effector/delivery-actions-store'
import EffectorContextProvider from '$components/UI/React/EffectorContextProvider'
import { Routes } from '$root/utils/constants/routes'
import { formatPhone } from '$root/utils/functions'

const PickUpDeliveryListItemWrapper = ({ children }) => {
  return <div className="pickup-delivery">{children}</div>
}

const PickUpDeliveryClient = ({ delivery }) => {
  const phone = useMemo(() => {
    const contacts = delivery.client.contacts || []
    const phoneContact = _.first(
      contacts.filter(
        (contact) => contact.contact_type_code === ContactType.Phone,
      ),
    )

    if (phoneContact) {
      return formatPhone(phoneContact.value)
    }
  }, [delivery.contacts])

  return (
    <div className="pickup-delivery__column pickup-delivery__client-column">
      <div
        className="pickup-delivery__client-fio link pointer"
        onClick={() => {
          window.open(
            `${route(Routes.clients.view, { id: delivery.client.id })}`,
            '_blank',
          )
        }}
      >
        {delivery.client.full_name}
      </div>
      <div className="pickup-delivery__client-phone">{phone}</div>
    </div>
  )
}

const PickUpDeliveryResponsible = ({ delivery }) => {
  return (
    <div className="pickup-delivery__column pickup-delivery__responsible">
      <div>{delivery.manager && delivery.manager.full_name}</div>
    </div>
  )
}

const PickUpDeliveryRations = ({ delivery }) => {
  return (
    <div className="pickup-delivery__column pickup-delivery__rations-column">
      {delivery.rations &&
        delivery.rations.map((ration, i) => (
          <div className="pickup-delivery__ration" key={i}>
            {ration.name}
          </div>
        ))}
    </div>
  )
}

const PickUpDeliveryGeneralInfo = ({ delivery }) => {
  const { pickupService } = useContext(EffectorContext)

  return (
    <div className="pickup-delivery__column pickup-delivery__general-info-column">
      <DeliveryInfoField
        className="delivery-type"
        order={delivery}
        allowEdit={true}
        needTime={false}
        needDayType={false}
        onChange={(updatedDelivery) => {
          pickupService.updateDelivery(updatedDelivery)
        }}
      />
      <div className="pickup-delivery__comment">{delivery.comment}</div>
    </div>
  )
}

const PickUpDeliveryInvoice = ({ delivery }) => {
  const { pickupService } = useContext(EffectorContext)
  const { openModal } = useModals()
  const { enums } = usePage().props

  pickupService.useContainer(delivery)

  const { paySystems = {} } = enums

  const invoiceTitle = `№${delivery.invoice && delivery.invoice.id}`

  const showEditInvoiceForm = (invoice) => {
    openModal('EditInvoice', Modal, {
      classes: { wrapper: 'edit-invoice' },
      title: `Счет ${invoiceTitle}` || 'Счет',
      contentComponent: EditOneInvoice,
      invoice,
      onSave: (invoiceModified) => {
        pickupService.saveInvoice(invoiceModified)
      },
    })
  }

  const invoiceIsPaid = pickupService.invoiceIsPaid(delivery)

  return (
    <div className="pickup-delivery__column pickup-delivery__invoice-column">
      <div className="pickup-delivery-invoice">
        {(pickupService.clientHasInvoices(delivery) && (
          <div className="pickup-delivery-invoice__amount-wrapper">
            <div className="pickup-delivery-invoice__number">Счет</div>
            <div
              className="pickup-delivery-invoice__amount"
              onClick={() => pickupService.showInvoicesModal(delivery)}
            >
              Несколько счетов
            </div>
          </div>
        )) ||
          (pickupService.clientIsPrePaid(delivery) && (
            <>
              <div className="pickup-delivery-invoice__amount-wrapper">
                <div className="pickup-delivery-invoice__number">
                  Счет {invoiceTitle}
                </div>
                <div className="pickup-delivery-invoice__amount">
                  {delivery.invoice && delivery.invoice.amount}
                </div>
              </div>
              <div className="pickup-delivery-invoice__pay-type-wrapper">
                <span className="pickup-delivery-invoice__pay-type-title">
                  Оплата
                </span>
                <div className="pickup-delivery-invoice__pay-type">Оплачен</div>
              </div>
            </>
          )) || (
            <>
              <div className="pickup-delivery-invoice__amount-wrapper">
                <div className="pickup-delivery-invoice__number">
                  Счет {invoiceTitle}
                </div>
                <div
                  className="pickup-delivery-invoice__amount"
                  onClick={() =>
                    !invoiceIsPaid && showEditInvoiceForm(delivery.invoice)
                  }
                >
                  {delivery.invoice && delivery.invoice.amount}
                </div>
              </div>
              <div className="pickup-delivery-invoice__pay-type-wrapper">
                <span className="pickup-delivery-invoice__pay-type-title">
                  Оплата
                </span>
                <div className="pickup-delivery-invoice__pay-type">
                  {(invoiceIsPaid && <>Оплачен</>) ||
                    (paySystems &&
                      delivery.invoice &&
                      paySystems[delivery.invoice.pay_system])}
                </div>
              </div>
            </>
          )}
      </div>
    </div>
  )
}

const PickUpDeliveryActions = ({ delivery }) => {
  const { closeModal } = useModals()
  const { pickupService, deliveryActions: actionsService } =
    useContext(EffectorContext)

  return (
    <div className="pickup-delivery__column pickup-delivery__actions-column">
      <div className="pickup-delivery-actions">
        <div
          className="pickup-delivery-actions__given-btn"
          onClick={() =>
            actionsService.onGiven((data) => {
              closeModal()
              pickupService.orderGiven(delivery, data)
            })
          }
        >
          Отдано
        </div>
        <div
          className="pickup-delivery-actions__delete-btn"
          onClick={() =>
            actionsService.onProblem((data) => {
              closeModal()
              pickupService.orderProblem(delivery, data)
            })
          }
        />
      </div>
    </div>
  )
}

const PickUpDeliveryListItem = ({ delivery, actionsRestrictions }) => {
  const { openModal } = useModals()

  const deliveryActions = EffectorServices.getService({
    service: new DeliveryActionsStore({
      persistKey: `pickup-delivery-actions-${delivery.id}`,
      delivery,
    }),
    options: {
      openModal,
    },
  })

  return (
    <EffectorContextProvider services={{ deliveryActions }}>
      <PickUpDeliveryListItemWrapper>
        <PickUpDeliveryClient delivery={delivery} />
        <PickUpDeliveryResponsible delivery={delivery} />
        <PickUpDeliveryRations delivery={delivery} />
        <PickUpDeliveryGeneralInfo delivery={delivery} />
        <PickUpDeliveryInvoice delivery={delivery} />
        {!actionsRestrictions && (
          <PickUpDeliveryActions delivery={delivery} />
        )}
      </PickUpDeliveryListItemWrapper>
    </EffectorContextProvider>
  )
}

export default PickUpDeliveryListItem

import React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { ContactType } from '$root/utils/constants/clients'
import { PhoneInput } from '$components/UI/Inputs/PhoneInput'
import { Label } from '$components/UI/Inputs/Label'
import clsx from 'clsx'

export const PhoneContact = ({
  markErrors = false,
  errors = {},
  contacts,
  clientId,
  onChange,
  getNewContactsWithUpdatedFn,
}) => {
  // const [phones, setPhones] = useState(contacts.filter(contact => contact.contact_type_code === ContactType.Phone))
  const phones = contacts.filter(
    (contact) => contact.contact_type_code === ContactType.Phone,
  )

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const grid = 8

  const getItemStyle = (isDragging, draggableStyle) => {
    if (draggableStyle.left) {
      delete draggableStyle['left']
    }
    return {
      // some basic styles to make the items look a bit nicer
      userSelect: 'none',
      padding: grid * 2,
      // marginBottom: isDragging && `${ grid }px` || 'unset',
      // change background colour if dragging
      background: isDragging ? '#14b63236' : '#add8e63b',
      // styles we need to apply on draggables
      ...draggableStyle,
    }
  }

  const addContactSort = (contact, i) => ({
    ...contact,
    client_id: clientId,
    sort: i + 1,
  })

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? '#add8e63b' : 'transparent',
    border: 'dashed 2px grey',
    padding: grid,
    width: '100%',
    gap: '10px',
    display: 'flex',
    flexFlow: 'column',
  })

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const items = reorder(phones, result.source.index, result.destination.index)

    onChange(items.map(addContactSort))
  }

  const deletePhone = (phone) => {
    onChange(phones.filter((_phone) => _phone !== phone))
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity

  return (
    <>
      <div className="flex align-items-center justify-between mb-1">
        <Label text="Телефоны" style={{ margin: 'unset' }} />
        <div className="comment-dropdown comment-dropdown--green js-dropdown">
          <button
            className="comment-dropdown__btn js-dropdown-btn"
            onClick={() =>
              onChange(
                getNewContactsWithUpdatedFn({
                  contact_type_code: ContactType.Phone,
                  value: null,
                }).map(addContactSort),
              )
            }
          >
            +
          </button>
        </div>
      </div>

      {phones.length > 0 && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(droppableProvided, droppableSnapshot) => (
              <div
                ref={droppableProvided.innerRef}
                style={getListStyle(droppableSnapshot.isDraggingOver)}
              >
                {phones.map((item, index) => (
                  <Draggable
                    key={item._id}
                    draggableId={item._id}
                    index={index}
                  >
                    {(draggableProvided, draggableSnapshot) => (
                      <div
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                        style={getItemStyle(
                          draggableSnapshot.isDragging,
                          draggableProvided.draggableProps.style,
                          index === phones.length - 1,
                        )}
                      >
                        <div className="clients-phone">
                          <div className="clients-phone__head">
                            {/*<span>{index === 0 && 'Основной'}</span>*/}
                            {index === 0 && (
                              <Label
                                text="Основной"
                                className="form__title general"
                              />
                            )}
                          </div>
                          <div className="clients-phone__content">
                            <PhoneInput
                              subtitle={
                                <button
                                  className="clients__item-delete"
                                  onClick={() => deletePhone(item)}
                                >
                                  Удалить
                                </button>
                              }
                              data-testid={`phone-${item._id}`}
                              placeholder="Телефон"
                              value={item.value || ''}
                              classes={clsx('clients-phone__item', {
                                error:
                                  markErrors &&
                                  !!errors[`contacts-${item._id}`],
                              })}
                              onChange={(value) => {
                                onChange(
                                  getNewContactsWithUpdatedFn({
                                    ...item,
                                    value,
                                  }).map(addContactSort),
                                )
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  )
}

import {
  EffectorServices,
  EffectorStore,
} from '$root/utils/effector/effector-services'
import { createEffect, createEvent, createStore, sample } from 'effector'
import { useStore } from 'effector-react'
import { uid } from '$root/utils/functions'
import { useCallback, useEffect } from 'react'
import { router, useForm } from '@inertiajs/react'
import { useNewFormValidation } from '$hooks/useFormValidation'
import { InvoicePayableType, InvoiceStatus } from '$lib/invoices/utils/const'
import { PaySystem } from '$root/utils/constants/payment'

export class InvoiceStore extends EffectorStore {
  params = { invoice: null, listService: null }
  store = { $invoice: null, invoice: null }
  form = null

  services = {
    // Форма редакировани / валидация
    formService: null,
    // Управление просмотром / редактированием компонента с помощью hover
    hoveringEditableService: null,
    // Обработка действий инвойса
    actionsService: null,
  }

  events = {
    setInvoice: null,
  }

  _initPersist() {
    return `invoice-${this.serviceId}`
  }

  _initServices() {
    this.services.formService = EffectorServices.getService({
      service: new InvoiceFormService({ parent: this }),
      recursive: true,
    })
    this.services.actionsService = EffectorServices.getService({
      service: new InvoiceActionsService({ parent: this }),
      recursive: true,
    })
    this.services.hoveringEditableService = EffectorServices.getService({
      service: new InvoiceHoverEditableService({ parent: this }),
      recursive: true,
    })
  }

  reset() {
    this.services.hoveringEditableService.setIsEdit(false)
  }

  constructor({ invoice, listService, parent }) {
    super({ parent })

    this.params = {
      invoice,
      listService,
    }
  }

  get serviceId() {
    return this.params.invoice.id || this.params.invoice._id
  }

  init() {
    this.events.setInvoice = createEvent()

    this.store.$invoice = createStore(this.params.invoice).on(
      this.events.setInvoice,
      (state, invoice) => invoice,
    )
  }

  useContainer() {
    this.store.invoice = useStore(this.store.$invoice)
    this.form = useForm(this.store.invoice)

    useEffect(() => {
      this.form.setData(this.store.invoice)
    }, [this.store.invoice])
  }

  get isMayPayed() {
    return !!this.store.invoice.id && !this.isPaid && !this.isCancel
  }

  get isMayCancel() {
    return !!this.store.invoice.id && !this.isCancel
  }

  get isPaid() {
    return this.store.invoice.status === InvoiceStatus.Paid
  }

  get allowEdit() {
    return !this.store.invoice.disallowEdit && !this.isCancel
  }

  get isCancel() {
    return this.store.invoice.status === InvoiceStatus.Canceled
  }

  get isHiddenInvoice() {
    const currentPageIsCourier = route().current().indexOf('mobile.') >= 0
    return (
      currentPageIsCourier &&
      (this.store.invoice.status === InvoiceStatus.Paid ||
        this.store.invoice.pay_system === PaySystem.Inner)
    )
  }

  onEdit() {
    if (this.parent) {
      this.parent.onEdit(this.serviceId)
    }
  }

  updateInvoice(invoice) {
    this.events.setInvoice({ ...this.store.invoice, ...invoice })
  }
}

export class InvoiceListStore extends EffectorStore {
  dateToSaveFormat = (val) => val
  onSave = undefined
  saveRoute = undefined
  saveParams = undefined

  edited = []

  DEFAULT_INVOICE = {
    date_bill: null,
    date_paid: null,
    pay_system: null,
    amount: 0,
    status: null,
  }

  params = {
    invoices: [],
  }

  store = {
    $invoices: null,
    invoices: [],
  }

  events = {
    newInvoice: null,
    reset: null,
    setInvoices: null,
  }

  init({ dateToSaveFormat, onSave }) {
    this.dateToSaveFormat = dateToSaveFormat
    this.onSave = onSave

    this.events.newInvoice = createEvent()
    this.events.reset = createEvent()
    this.events.setInvoices = createEvent()

    this.store.$invoices = createStore(
      this._buildInvoicesServices(this.params.invoices),
    )
      .on(this.events.newInvoice, (invoices, invoice) => [invoice, ...invoices])
      .on(this.events.setInvoices, (state, invoices) => invoices)
      .reset(this.events.reset)

    const onNewInvoiceFx = createEffect((invoiceService) => {
      this.edited.push(invoiceService.params.invoice)
    })

    sample({
      clock: this.events.newInvoice,
      target: onNewInvoiceFx,
    })
  }

  constructor({
    invoices,
    saveRoute,
    saveParams = undefined,
    persistKey = undefined,
  }) {
    super({ persistKey })
    this.saveRoute = saveRoute
    this.saveParams = saveParams
    this.params.invoices = invoices || []
  }

  _reset() {
    for (const invoiceService of this.store.invoices) {
      invoiceService.reset()
    }
    this.events.reset()
  }

  _getEmptyInvoice() {
    return {
      ...this.DEFAULT_INVOICE,
      _id: uid(),
      date_bill: this.dateToSaveFormat(new Date()),
    }
  }

  _buildInvoicesServices(invoices) {
    return invoices.map((invoice) => this._buildInvoiceService(invoice))
  }

  _buildInvoiceService(invoice) {
    return EffectorServices.getService({
      service: new InvoiceStore({
        invoice,
        listService: this,
        parent: this,
      }),
    })
  }

  useContainer() {
    this.store.invoices = useStore(this.store.$invoices)
  }

  validate() {
    let isValid = true
    for (const invoiceService of this.store.invoices) {
      if (!invoiceService.services.formService.validateForm()) {
        invoiceService.services.hoveringEditableService.setIsEdit(true)
        isValid = false
      }
    }
    return isValid
  }

  newInvoice() {
    this.events.newInvoice(this._buildInvoiceService(this._getEmptyInvoice()))
  }

  checkOnUpdatedInvoices(invoices) {
    useEffect(() => {
      const invoiceServices = this._buildInvoicesServices(invoices)
      for (const invoiceService of invoiceServices) {
        const index = invoices.findIndex(
          (invoice) =>
            !!invoiceService.store.invoice &&
            invoice.id === invoiceService.store.invoice.id,
        )
        if (index >= 0) {
          invoiceService.updateInvoice(invoices[index])
        }
      }

      this.events.setInvoices(invoiceServices)
    }, [invoices])
  }

  onEdit(invoiceServiceId) {
    if (!this.edited.includes(invoiceServiceId)) {
      this.edited.push(invoiceServiceId)
    }
  }

  get sendingInvoices() {
    return this.store.invoices
      .filter((invoiceService) =>
        this.edited.includes(invoiceService.serviceId),
      )
      .map((invoiceService) => {
        return {
          id: invoiceService.form.data.id,
          date_bill: invoiceService.form.data.date_bill,
          amount: invoiceService.form.data.amount,
          pay_system: invoiceService.form.data.pay_system,
          status:
            invoiceService.form.data.status ||
            (!invoiceService.form.data.id && InvoiceStatus.Pending),
        }
      })
  }

  save(validationErrorCb) {
    if (this.validate()) {
      const sendingInvoices = this.sendingInvoices
      const saveRoute =
        (typeof this.saveRoute === 'function' && this.saveRoute()) ||
        this.saveRoute

      if (sendingInvoices.length > 0) {
        const $this = this
        const saveParams =
          (this.saveParams && this.saveParams()) || route().params

        const invoice = _.first(sendingInvoices)

        router.post(
          route(saveRoute, {
            id: saveParams.id || invoice.payable_id,
            status: saveParams.status,
          }),
          { invoices: sendingInvoices },
          {
            onSuccess() {
              $this.reset()
              if ($this.onSave) {
                $this.onSave()
              }
            },
          },
        )
      }
    } else {
      validationErrorCb()
    }
  }

  saveList() {}
}

export class InvoiceActionsService extends EffectorStore {
  _initPersist() {
    return `actions-service`
  }
}

export class InvoiceHoverEditableService extends EffectorStore {
  store = {
    isEditable: true,
    $isEditable: true,
    isEdit: false,
    $isEdit: null,
  }

  events = {
    setIsEditable: null,
    setIsEdit: null,
  }

  init() {
    this.events.setIsEditable = createEvent()
    this.events.setIsEdit = createEvent()

    this.store.$isEditable = createStore(this.store.isEditable).on(
      this.events.setIsEditable,
      (state, isEditable) => isEditable,
    )

    this.store.$isEdit = createStore(false).on(
      this.events.setIsEdit,
      (state, isEdit) => isEdit,
    )
  }

  _initPersist() {
    return `editable-service`
  }

  setIsEdit(isEdit) {
    this.events.setIsEdit(isEdit)
  }

  useStore() {
    this.store.isEdit = useStore(this.store.$isEdit)
    this.store.isEditable = useStore(this.store.$isEditable)
  }

  useHeaderContainer() {
    this.useStore()
  }
}

export class InvoiceFormService extends EffectorStore {
  form = null

  validateForm = null

  store = {
    markErrors: false,
    $markErrors: null,
  }

  events = {
    setMarkErrors: null,
  }

  _initPersist() {
    return `form-service`
  }

  init() {
    this.events.setMarkErrors = createEvent()
    this.store.$markErrors = createStore(false).on(
      this.events.setMarkErrors,
      (state, markErrors) => markErrors,
    )
  }

  useContainer() {
    this.form = this.parent.form

    this.store.markErrors = useStore(this.store.$markErrors)

    const validateFields = (data) => {
      const errors = {}

      if (!!data.id && !data.status) {
        errors['status'] = 'Обязательное поле'
      }

      if (!data.date_bill) {
        errors['date_bill'] = 'Обязательное поле'
      }

      if (!data.pay_system) {
        errors['pay_system'] = 'Обязательное поле'
      }

      if (!data.amount || data.amount <= 0) {
        errors['amount'] =
          'Необходимо указать положительное значение в поле суммы'
      }

      return errors
    }

    const [validate, markErrors] = useNewFormValidation({
      persistKey: `invoice-form-${this.parent.serviceId}`,
      onCheckIsValid: validateFields,
      form: this.form,
      showModal: false,
    })

    this.validateForm = useCallback(validate, [this.form.data])
    this.watchValidation(markErrors)

    return this.form
  }

  watchValidation(markErrors) {
    useEffect(() => {
      this.events.setMarkErrors(markErrors)
    }, [markErrors])
  }

  setFieldValue(name, value) {
    this.form.setData(name, value)
    this.parent.onEdit()
    // this.parent.updateInvoice({ [name]: value })
  }

  get sendingInvoice() {
    let invoice
    const data = this.form.data

    if (data.payable_type === InvoicePayableType.Order) {
      invoice = {
        id: data.id,
        status: data.status,
        pay_system: data.pay_system,
      }
    } else {
      invoice = {
        id: data.id,
        status: data.status,
        pay_system: data.pay_system,
        amount: data.amount,
      }
    }

    return invoice
  }
}

import { BalanceCard } from '$components/Clients/BalanceCard'
import { TransactionTable } from '$components/Clients/TransactionHistory/TransactionTable'
import { usePermissions } from '$hooks/usePermissions'
import InvoiceList from '$components/Clients/Invoices/InvoiceList'
import Invoice from '$components/Clients/Invoices/Invoice'
import InvoiceListHeaderActions from '$components/Clients/Invoices/InvoiceListHeaderActions'
import TransactionInvoicesTitle from '$components/Clients/TransactionHistory/TransactionInvoicesTitle'
import { NoResults } from '$components/UI/DataGrid/NoResults'
import { EffectorServices } from '$root/utils/effector/effector-services'
import { InvoiceListStore } from '$lib/invoices/new-effector/invoice-list-new-store'
import EffectorContextProvider from '$components/UI/React/EffectorContextProvider'
import { useDateTime } from '$hooks/useDateTime'
import InvoicePayBtn from '$components/Clients/Invoices/InvoicePayBtn'
import { Routes } from '$root/utils/constants/routes'
import InvoiceCancelBtn from '$components/Clients/Invoices/InvoiceCancelBtn'

export const TransactionHistory = ({ client, routes, transactions }) => {
  const { dateToSaveFormat } = useDateTime()

  const invoices = client.invoices

  const invoicesService = EffectorServices.getService({
    service: new InvoiceListStore({
      invoices,
      persistKey: 'transactions-invoices-list',
      saveRoute: routes.invoices.update,
    }),
    options: {
      dateToSaveFormat,
    },
  })

  invoicesService.useContainer()
  invoicesService.checkOnUpdatedInvoices(invoices)

  const { isAllow } = usePermissions()

  return (
    <EffectorContextProvider services={{ invoicesService }}>
      <BalanceCard client={client} transaction={transactions} />
      <TransactionTable
        client={client}
        routes={routes}
        transactions={transactions}
        allowChange={isAllow('clients.edit')}
        invoiceList={
          <>
            <TransactionInvoicesTitle
              title="Счет"
              action={<InvoiceListHeaderActions routes={routes} />}
            />
            <InvoiceList
              invoices={
                <>
                  {(invoicesService.store.invoices.length > 0 &&
                    invoicesService.store.invoices.map((invoiceService) => {
                      return (
                        <EffectorContextProvider
                          services={{ invoiceService }}
                          key={invoiceService.persistKey}
                        >
                          <Invoice
                            headerActions={({ onPay, onCancel }) => (
                              <>
                                {invoiceService.isMayPayed && (
                                  <InvoicePayBtn
                                    onPay={onPay}
                                    route={Routes.mobile.payInvoice}
                                  />
                                )}
                                {invoiceService.isMayCancel && (
                                  <InvoiceCancelBtn
                                    onPay={onCancel}
                                    route={Routes.mobile.cancelInvoice}
                                  />
                                )}
                              </>
                            )}
                          />
                        </EffectorContextProvider>
                      )
                    })) || <NoResults title={false} text="Нет счетов" />}
                </>
              }
            />
          </>
        }
      />
    </EffectorContextProvider>
  )
}
